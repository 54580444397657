import React, { useEffect, useState } from "react";
import Search from "../../components/SearchHeader";
import InputSearch from "../../components/SearchHeader/inputSeach";
import Table from "../../components/TableGenerator";
import TopMenu from "../../components/TopMenu";
import "../CommonStyle/style.css";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { captalizeFirstLetterEacheWord } from "../../utils/utils";
import { LossService } from "../../service/loss_service";
import { FarmService } from "../../service/farm_service";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const columns = [
	{
		id: "cultura",
		label:"Cultura",
	},
	{
		id: "variedade",
		label:"Variedade",
	},
	{
		id: "quantidade",
		label:"Quantidade",
	},
	{
		id: "unid",
		label:"Unidade de Medidade",
	},
	{
		id: "data",
		label:"Data",
	},
];

function Losses() {
	const [search, setSearch] = useState("");
	const [losses, setLosses] = useState([]);
	const lossService = new LossService();
	const farmService = new FarmService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const losses = await lossService.getByProperty(propertyId);
		setLosses(await getPerdasForProperty(losses));
	};
	
	const getPerdasForProperty = async (losses) => {
		const lossesWithDetails = [];
		for (let i = 0; i < losses.length; i++) {
			const loss = losses[i];
			let info = {};
			if (loss.loteId && loss.loteId !== "")
				info = await getLoteInfo(loss.lote);
			else if (loss.safraId && loss.safraId !== "")
				info = await getSafraInfo(loss.safra);
			lossesWithDetails.push({
				id: loss.id,
				itemId: loss.id,
				cultura: captalizeFirstLetterEacheWord(info.cultura),
				variedade: captalizeFirstLetterEacheWord(info.variedade ?? ""),
				quantidade: loss.quantidade,
				unid: info.unid,
				data: formatDate(loss.data),
			});
		}
		return lossesWithDetails;
	};
	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};
	const getSafraInfo = async (safra) => {
		return await getCultivoInfo(safra.propriedadeCultivoId);
	};

	const getLoteInfo = async (lote) => {
		return await getCultivoInfo(lote.propriedadeCultivoId);
	};

	const getCultivoInfo = async (propertyCultureId) => {
		const propertyCulture = await farmService.getById(propertyCultureId);
		const embalagem = propertyCulture.embalagens;
		const unidade = embalagem[0].unidadeMedida;
		const cultivo = propertyCulture.cultivo;
		return {
			cultura: captalizeFirstLetterEacheWord(cultivo.cultura),
			variedade: captalizeFirstLetterEacheWord(cultivo.variedade ?? ""),
			unid: unidade.sigla,
		};
	};

	//função pesquisa
	const dataFilter = losses.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);
	function handleClick(e) {
		setSearch(e.target.value);
	}
	const handleDelete = async (id) => {
		await lossService.changeVisibility(id);
		initData();
	};
	return (
		<div>
			<TopMenu open={["launch", "losses"]} help="losses" />
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<Search
									title="Perdas"
									href="/perdas/cadastroPerdas"
									textButton="Nova Perda"
								>
									<InputSearch func={handleClick} />
								</Search>
							</Item>
						</Grid>
						<Grid item xs={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									p: 0,
									m: 0,
								}}
							>
								<Table
									data={dataFilter}
									columns={columns}
									handleDelete={handleDelete}
									editHref="/perdas/cadastroPerdas?id="
								/>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default Losses;
