import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SearchHeader from "../../../components/SearchHeader";
import InputSearch from "../../../components/SearchHeader/inputSeach";
import Table from "../../../components/TableStockInsumoDetailed";
import TopMenu from "../../../components/TopMenu";
import styles from "./StockInsumoDetailed.module.css";

// import { data } from "../dataStock.js";
import { StockService } from "../../../service/stock_service";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
// import data from "../../../dataTests/dataPedidosModal.json";

const columns = {
	produtos: "Insumo",
	DataEntrega: "Data de entrada de insumo",
	quantidade: "Quant. Embalagem",
	capacidadeEmbalagem: "Capacidade Embalagem",
	total: "Total",
};

// const style = {
// 	position: "absolute",
// 	top: "50%",
// 	left: "50%",
// 	transform: "translate(-50%, -50%)",
// };

function StockInsumoDetailed() {
	const [data, setData] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [nomeInsumo, setNomeInsumo] = useState("");

	const { id } = useParams();
	const stockService = new StockService();

	useEffect(() => {
		initData();
	}, []);
	const custonOp = [
		{
			item: "Visualizar receituário",
			func: function (e) {
				window.location.href = `/pdf/?pag=VisualizacaoReceituario&index=${id}&id=${e}`;
			},
		},
		{
			item: "Visualizar nota fiscal",
			func: function (e) {
				window.location.href = `/pdf/?pag=VisualizacaoNotaFiscal&index=${id}&id=${e}`; 
			},
		},
	];

	const onDelete = async (id) => {
		await stockService.changeVisibilityEntry(id);
		initData();
	};

	const initData = async () => {
		const inputPropertyEntries = await stockService.getEntries(id);
		let auxData = [];
		inputPropertyEntries.forEach(entry => {
			const inputName = entry.propriedadeInsumo.insumoEmGrupo.insumo.nome;
			setNomeInsumo(inputName);
			auxData.push({
				id: entry.id,
				produtos: inputName,
				DataEntrega: new Date(entry.data).toLocaleDateString("pt-BR"),
				quantidade: entry.quantidade,
				capacidadeEmbalagem: entry.capacidadeEmbalagem,
				total: entry.quantidade * entry.capacidadeEmbalagem,
			});
		});
		setData(auxData);
	};

	return (
		<div>
			<TopMenu open={["launch", "requests"]} help="requestsItemDetelhes" />
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<SearchHeader
									headerComponents={
										<Link
											to={"/estoque"}
											className={styles.headerRegisterAreaButton}
										>
											<ArrowBackIcon sx={{ mr: 2 }} />
											Insumo: {nomeInsumo}
										</Link>
									}
									// href="/pedidos/cadastroPedido"
									// textButton="Novo Pedido"
								>
									<InputSearch func={initData} />
								</SearchHeader>
							</Item>
						</Grid>
						<Grid item xs={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									p: 0,
									m: 0,
								}}
							>
								<Table
									data={data}
									keys={"1"}
									columns={columns}
									custonOpition={custonOp}
									handleDelete={onDelete}
									hrefEdit={"/estoque/cadastroEstoque/"}
								/>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
			
		</div>
	);
}
export default StockInsumoDetailed;
