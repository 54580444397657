/* eslint-disable no-sparse-arrays */
import React, { useEffect, useState } from "react";

import { ManagementService } from "../../../service/management_service";
import { captalizeFirstLetterEacheWord, getDateWithoutTimezone } from "../../../utils/utils";
//import data activities
import Table from "../../TableGenerator";

function TableManagRequested(props) {
	const [search, setSearch] = useState("");
	const [allManejos, setAllManejos] = React.useState([]);
	const [columns, setColumns] = useState([
		{
			id: "servico",
			label: "Serviço",
		},
		{
			id: "insumo",
			label: "Insumo",
		},,				
		{
			id: "data",
			label: "Data",
		},
		{
			id: "safra",
			label: "Safra",
		},
		{
			id: "operador",
			label: "Operador",
		},
		// {
		// 	id: "solicitante",
		// 	label: "Solicitante",
		// },				
	]);

	const managementService = new ManagementService();

	useEffect(() => setSearch(props.search), [props]);

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const user = localStorage.getItem("user");
		
		let filter = {};
		if(!user.admin) filter.solicitante = user.id;

		const params = {
			page: 1,
			limit: 100000,
			filterValue: encodeURI(JSON.stringify(filter))
		};

		const managements = await managementService.getByProperty(propertyId, params);
		setAllManejos(await getManejoDetails(managements, user.admin));

	};

	const getManejoDetails = async (manejos, userAdmin) => {
		const manejosWithDetails = [];
		for (let i = 0; i < manejos.length; i++) {
			const manejo = manejos[i];
			const safra = manejo.safra;
			const talhao = safra.talhao;
			const farm = await safra.propriedadeCultivo;
			const cultivation = farm.cultivo;
			let produtos = "";
			let quantity = "";
			const propertyInputs = manejo.propriedadeInsumos;
			for (let j = 0; j < propertyInputs.length; j++) {
				const propertyInput = propertyInputs[j].propriedadeInsumo;
				const insumo = propertyInput.insumoEmGrupo.insumo;
				produtos += insumo.nome + (j < propertyInputs.length - 1 ? ", " : "");
				quantity +=
					propertyInput.quantidade + (j < propertyInputs.length - 1 ? ", " : "");
			}
			if (manejo.quantidadeVolumeCalda)
				quantity += `/ ${manejo.quantidadeVolumeCalda}`;
			
			const propertyServico = manejo.propriedadeServico;
			const servico = propertyServico ? propertyServico.servico : null;
			const date = getDateWithoutTimezone(manejo.dataAplicacao);
			const subDiv = safra?.subdivisao;
			const catSub = subDiv?.categoriaSubdivisao;
			const operador = manejo.usuarioResponsavel;
			const solicitante = manejo.usuarioSolicitante;
			
			let newManejo = {
				id: manejo.id,
				itemId: manejo.id,
				safra: `Talhão ${talhao.nome} ${
					subDiv ? captalizeFirstLetterEacheWord(catSub) + " " + subDiv?.complemento : ""
				} - ${captalizeFirstLetterEacheWord(cultivation.cultura)} ${captalizeFirstLetterEacheWord(cultivation.variedade ?? "")} ${formatDate(
					safra.dataPlantio
				)}`,
				insumo: produtos !== "" ? produtos : "Não aplicado",
				servico: servico ? servico.nome : "Não aplicado",
				quantidade: quantity !== "" ? quantity : "Não se aplica",
				data: formatDate(date),
				operador:
					operador !== null && operador !== undefined && operador !== ""
						? operador.nome
						: "não definido",
				solicitante: solicitante?.nome ?? "não definido",

			};
			manejosWithDetails.push(newManejo);
		}
		if (userAdmin) {
			// eslint-disable-next-line no-sparse-arrays
			setColumns([
				{
					id: "servico",
					label: "Serviço",
				},
				{
					id: "insumo",
					label: "Insumo",
				},,				
				{
					id: "data",
					label: "Data",
				},
				{
					id: "safra",
					label: "Safra",
				},
				{
					id: "operador",
					label: "Operador",
				},
				{
					id: "solicitante",
					label: "Solicitante",
				},				
			]);
		} else {
			// eslint-disable-next-line no-sparse-arrays
			setColumns([
				{
					id: "servico",
					label: "Serviço",
				},
				{
					id: "insumo",
					label: "Insumo",
				},,				
				{
					id: "data",
					label: "Data",
				},
				{
					id: "safra",
					label: "Safra",
				},
				{
					id: "operador",
					label: "Operador",
				},
				// {
				// 	id: "solicitante",
				// 	label: "Solicitante",
				// },				
			]);
		}
		return manejosWithDetails;
	};
	
	const dataFilter = allManejos.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);

	const formatDate = (dateToFormat) => {
		const date = getDateWithoutTimezone(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	const handleDelete = async (id)  => {
		await managementService.changeVisibility(id);
		initData();
	};

	return (
		<>
			<Table
				data={dataFilter}
				columns={columns}
				editHref={"/manejo/cadastroManejo?id="}
				handleDelete={handleDelete}
			/>
		</>
	);
}

export default TableManagRequested;
