import React, { useEffect, useState } from "react";

import { ManagementService } from "../../../service/management_service";
import { captalizeFirstLetterEacheWord, getDateWithoutTimezone } from "../../../utils/utils";
//import data activities
import Table from "../../TableGenerator";

//column

function TableManagAccomplished(props) {
	const [search, setSearch] = useState("");
	const [allManejos, setAllManejos] = React.useState([]);
	const columns = [
		{
			id: "safra",
			label: "Safra",
		},
		{
			id: "servico",
			label: "Serviço",
		},
		{
			id: "operadores",
			label: "Nº de operadores",
		},
		{
			id: "duracao",
			label: "Duração do serviço",
		},
		{
			id: "insumo",
			label: "Insumo",
		},
		{
			id: "quantidade",
			label: "Quantidade/Dosagem",
		},
		{
			id: "data",
			label: "Data de Realização",
		},
		{
			id: "operador",
			label: "Operador",
		}	
	];

	const managementService = new ManagementService();

	useEffect(() => setSearch(props.search), [props]);

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const user = localStorage.getItem("user");
		
		let filter = { pendente: false };
		if(!user.admin) filter.responsavel = user.id;

		const params = {
			page: 1,
			limit: 100000,
			filterValue: encodeURI(JSON.stringify(filter))
		};

		const managements = await managementService.getByProperty(propertyId, params);
		setAllManejos(await getManejoDetails(managements));
	};

	const getManejoDetails = async (manejos) => {
		const manejosWithDetails = [];
		for (let i = 0; i < manejos.length; i++) {
			const manejo = manejos[i];
			const safra = manejo.safra;
			const talhao = safra.talhao;
			const farm = safra.propriedadeCultivo;
			const cultivation = farm.cultivo;
			let produtos = "";
			let quantity = "";
			const propertyInputs = manejo.propriedadeInsumos;
			for (let j = 0; j < propertyInputs.length; j++) {
				const propertyInput = propertyInputs[j].propriedadeInsumo;
				const insumo = propertyInput.insumoEmGrupo.insumo;
				produtos += insumo.nome + (j < propertyInputs.length - 1 ? ", " : "");
				quantity +=
					propertyInputs[j].quantidade + propertyInputs[j].propriedadeInsumo.unidadeMedida.sigla + " " + (j < propertyInputs.length - 1 ? ", " : "");
			}
			if (manejo.quantidadeVolumeCalda && manejo.quantidadeVolumeCalda > 0)
				quantity += `/ ${manejo.quantidadeVolumeCalda}`;
			const propertyServico = manejo.propriedadeServico;
			const servico = propertyServico ? propertyServico.servico : null;
			const date = getDateWithoutTimezone(manejo.dataAplicacao);
			const subDiv = safra?.subdivisao;
			const catSub = subDiv?.categoriaSubdivisao;
			const usuario = manejo.usuarioResponsavel;

			const totalDuration = manejo.quantidade_de_operadores * (manejo.duracaoMinutos ?? 0);
			const totalDurationText = `${convertToHoursText(totalDuration)}:${convertToMinutesText(totalDuration)}`;
			
			let newManejo = {
				id: manejo.id,
				itemId: manejo.id,
				safra: `Talhão ${talhao.nome} ${
					subDiv ? captalizeFirstLetterEacheWord(catSub) + " " + subDiv?.complemento : ""
				} - ${captalizeFirstLetterEacheWord(cultivation.cultura)} ${captalizeFirstLetterEacheWord(cultivation.variedade ?? "")} ${formatDate(
					safra.dataPlantio
				)}`,
				insumo: produtos !== "" ? produtos : "Não aplicado",
				servico: servico ? servico.nome : "Não aplicado",
				quantidade: quantity !== "" ? quantity : "Não se aplica",
				data: formatDate(date),
				operador:
					usuario !== null && usuario !== undefined && usuario !== ""
						? usuario.nome
						: "não definido",
				operadores: manejo.quantidade_de_operadores,
				duracao: totalDurationText,
			};
			manejosWithDetails.push(newManejo);
		}
		// if (userAdmin) {
		// 	setColumns(
		// 		[
		// 			{
		// 				id: "safra",
		// 				label: "Safra",
		// 			},
		// 			{
		// 				id: "servico",
		// 				label: "Serviço",
		// 			},
		// 			{
		// 				id: "insumo",
		// 				label: "Insumo",
		// 			},
		// 			{
		// 				id: "quantidade",
		// 				label: "Quantidade/Dosagem",
		// 			},
		// 			{
		// 				id: "data",
		// 				label: "Data de Realização",
		// 			},
		// 			{
		// 				id: "operador",
		// 				label: "Operador",
		// 			},
				
		// 		]
		// 	);
		// } else {
		// 	setColumns([
		// 		{
		// 			id: "safra",
		// 			label: "Safra",
		// 		},
		// 		{
		// 			id: "servico",
		// 			label: "Serviço",
		// 		},
		// 		{
		// 			id: "insumo",
		// 			label: "Insumo",
		// 		},
		// 		{
		// 			id: "quantidade",
		// 			label: "Quantidade/Dosagem",
		// 		},
		// 		{
		// 			id: "data",
		// 			label: "Data de Realização",
		// 		},
		// 		// {
		// 		// 	id: "operador",
		// 		// 	label: "Operador",
		// 		// },				
		// 	]);
		// }
		return manejosWithDetails;
	};

	const dataFilter = allManejos.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);

	const handleDelete = async (id)  => {
		await managementService.changeVisibility(id);
		initData();
	};

	const formatDate = (dateToFormat) => {
		const date = getDateWithoutTimezone(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	function convertToHoursText(number) {
		const hours = Math.floor(number / 60);

		let formattedHours = "";
		if(hours < 10)
			formattedHours = `0${hours}`;
		else
			formattedHours = hours.toString();
		
		return formattedHours;
	}

	function convertToMinutesText(number) {
		const minutes = number % 60;
		
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
		
		return formattedMinutes;
	}

	return (
		<>
			<Table
				data={dataFilter}
				columns={columns}
				editHref={"/manejo/cadastroManejo?id="}
				handleDelete={handleDelete}
			/>
		</>
	);
}

export default TableManagAccomplished;
